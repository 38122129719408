import { capitalize, isArray, isObject } from 'radash'
import { formatDate, formatOpenHouseDate } from '~~/src/helpers/date'
import type { OpenHouseAnnouncement, Log, PostCard } from '~~/src/types/Properties'

export const useLogs = () => {
    const statusHash: Record<string, string> = {
        status: 'Property Status',
        details: 'Property Details',
        photos: 'Property Photos',
        documents: 'Property Documents',
        description: 'Property Description',
        options: 'Optional Services',
        'open-house-announcements': 'Open House Announcements',
        'post-card': 'Postcard'
    }
    const getChangeInfoByContentType = (changeContentType: Log['changeContentType'], change: Log['changes'][number], value: unknown) => {
        switch (changeContentType) {
        case 'details':
            if (isArray(value)) {
                if (isObject(value[0])) {
                    value = (value as object[]).map((valueItem, i) =>
                        (`\n ${i + 1}. ${Object.values(valueItem).map(item => item || 'No Value').join(', ') || 'No Value'}`)
                    ).join('\n')
                } else { value = (value as string[]).join(', ') }
            }

            return `${change.fieldName} changed: ${value}`
        case 'status':
            return `Status changed from ${capitalize(change.oldValue as string)} to ${capitalize(change.newValue as string)}`
        case 'options':
            return 'Added Yard Sign'
        case 'open-house-announcements':
            return (change.newValue as OpenHouseAnnouncement[]).reduce((acc, value) => `${acc}${formatOpenHouseDate(value.dateStart!, value.dateEnd!)} - ${capitalize(value.status)}\n`, '')
        case 'post-card':
            if (typeof change.newValue === 'string') {
                const obj = JSON.parse(change.newValue)
                value = `Size [${obj.metadata.size}] - Qty: ${obj.quantity}`
            }
            return value
        default:
            return 'New value'
        }
    }
    const formatChanges = (changes: Log['changes'], record: Log) => {
        return changes.map((change) => {
            const newValue = change.newValue
            let changeInfo
            if (change.displayText) { changeInfo = change.displayText } else {
                changeInfo = getChangeInfoByContentType(record.changeContentType, change, newValue)
            }

            return { text: changeInfo, declineReason: change.declineReason, logType: record.logType }
        })
    }
    const listingStatusColorClasses = {
        you: 'bg-blue-100 text-blue-800',
        admin: 'bg-green-100 text-green-800'
    }
    const headList = [{ key: 'user', label: 'User' },
        { key: 'changeContentType', label: 'Change Type', format: (cell: unknown) => statusHash[cell as string] },
        { key: 'changes', label: 'Field & Changes', class: 'whitespace-pre-wrap' },
        {
            key: 'logType',
            label: 'Log Type',
            class: 'capitalize',
            format: (cell: unknown, record: any) => record.changeContentType === 'status' && record.changes[0]?.displayText ? 'Informational' : cell
        },
        { key: 'createdAt', label: 'Date', format: formatDate }
    ]

    return {
        headList,
        listingStatusColorClasses,
        formatChanges
    }
}
